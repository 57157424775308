/**
 * Returns array of related items
 * @param {array} edges of nodes.
 * @param {aaray} array of items
 * @returns {array}
 */

import removeTags from './remove-tags'

export function mergeArrays (nodeArr, itemArr) {
  if (!nodeArr.length || !itemArr.length) {
    return []
  }
  return nodeArr.map(({ node }) => {
    const { image, order, description } = itemArr
      .find(item => item.name === node.frontmatter.permalink)

    const commonProps = {
      order,
      type: node.fields.type,
      title: removeTags(node.frontmatter.title)
    }

    switch (node.fields.type) {
    case 'article':
      return {
        ...commonProps,
        slug: `/blog/${ node.fields.slug }/`,
        image: image || node.frontmatter.image,
        description: description || node.excerpt,
        author: node.frontmatter.author,
        authorImage: node.frontmatter.authorImage,
        date: node.frontmatter.date
      }
    case 'service':
      return {
        ...commonProps,
        slug: `/services/${ node.fields.slug }/`,
        image: image || node.frontmatter.image,
        description: description || node.frontmatter.whatWeDo
      }
    case 'new-service':
      return {
        ...commonProps,
        slug: `/services/${ node.fields.slug }/`,
        image: image || node.frontmatter.image,
        description: description || node.frontmatter.whatWeDo
      }
    case 'project':
      return {
        ...commonProps,
        slug: `/projects/${ node.fields.slug }/`,
        image: image || node.frontmatter.image,
        description: description || node.frontmatter.description
      }
    default:
      return {}
    }
  }).sort((a, b) => a.order - b.order)
}
