import React from 'react'

import Icon from '../UI/Icon/Icon'
import classes from './RelatedItems.module.scss'

const RelatedItems = ({ label, relatedItems }) => (
  <section className={[classes.RelatedSection, 'wrapper'].join(' ')}>
    <p className={classes.RelatedHeader}>{label}</p>
    <div className={classes.RelatedItems}>
      {relatedItems.map((item, index) => (
        <a
          href={item.slug}
          className={classes.RelatedItem} key={index}>
          <div className={classes.OuterImageWrapper}>
            <div className={classes.InnerImageWrapper}>
              <div className={classes.ItemTypeWrapper}>
                <Icon type={item.type} />
                <p className={classes.ItemType}>{item.type}</p>
              </div>
              {(item.author || item.date) &&
              <div className={classes.AuthorDateWrapper}>
                <img src={item.authorImage.childImageSharp.fixed.base64} alt="author-photo"/>
                <div>
                  <p className={classes.ItemAuthor}>{item.author}</p>
                  <p className={classes.ItemDate}>{item.date}</p>
                </div>
              </div>}
            </div>
            <img
              className={classes.RelatedItemImage}
              src={item.image.childImageSharp.fixed.src}
              loading="eager"
              alt={item.title}
            />
          </div>
          <p className={classes.RelatedItemHeader}>{item.title}</p>
          <div
            className={classes.RelatedItemDescription}
            dangerouslySetInnerHTML={{ __html: item.description }} />
        </a>
      ))}
    </div>
  </section>
)

export default RelatedItems
