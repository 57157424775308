import React from 'react'
import classes from './Contactus.module.scss'
import { Link } from 'gatsby'

/**
 * Contactus component
 **/

const ContactUs = ({
  contactUsDescription, backGroundColor, contactUsSlogan, buttonName
}) => {
  const cls = [classes.ContactUs]
  if (backGroundColor) {
    cls.push(classes.White)
  }
  return (
    <div className={cls.join(' ')}>
      <div className={`${ classes.ContactUsWrapper } wrapper`}>
        {contactUsDescription && (
          <div
            className={ classes.ContactUsDescription }
            dangerouslySetInnerHTML={{ __html: contactUsDescription }}
          />
        )}
        <div className={classes.ContactUsSloganWwrapper}>
          <div className={classes.ContactUsSlogan}>
            {contactUsSlogan}
          </div>
          <Link
            className={classes.ContactUsButton}
            to="/contact-us/"
          >
            {buttonName}
          </Link>
        </div>
      </div>
    </div>
  )
}

export default ContactUs
