import React, { useState, Fragment } from 'react'
import { graphql, Link } from 'gatsby'

import RelatedItems from '../components/RelatedItems/RelatedItems'
import SEO from '../components/Seo'
import Icon from '../components/UI/Icon/Icon'
import ContactUs from '../components/Contactus/Contactus'
import { mergeArrays } from '../utils/mergeArrays'
import { slugify } from '../utils/utilityFunctions'

const TechnologiesPage = ({ data }) => {
  const [counter, setCounter] = useState(1)

  const {
    header: {
      html: subheaderText,
      frontmatter: {
        overHeader,
        header
      }
    },
    seo: {
      frontmatter: {
        seo: seoInfo
      }
    },
    technologies: {
      frontmatter: { technologies }
    },
    howWeCanHelpYou: {
      html: howWeCanHelpYouDescription,
      frontmatter: {
        slogan,
        buttonText
      }
    },
    interestingForYouItems: {
      frontmatter: {
        label,
        interestingForYou
      }
    },
    relatedItems: {
      edges
    }
  } = data

  // added order to related item, will use it to
  // show correct orders on Intresting For You section
  const itemsList = interestingForYou
    .map((item, index) => Object.assign({ order: index }, item))

  // created array of objects which will be shown on Interesting for you section
  const intrestingForYouContent = mergeArrays(edges, itemsList)

  let renderTechnologies = []
  if (technologies.length !== 0) {
    renderTechnologies = technologies.slice(0, counter * 6)
  }
  const clsTop = ['top-header']
  if (subheaderText) {
    clsTop.push('subheader-present')
  }

  return (
    <Fragment>
      <SEO data={seoInfo} />
      <section className="general-technologies-page">
        <div className="top-section">
          <div className="top-section-text wrapper">
            {(overHeader || header) && (
              <div className={clsTop.join(' ')}>
                <p
                  className="header-begin"
                  dangerouslySetInnerHTML={{ __html: overHeader }}
                />
                <h1 className="header-end">{header}</h1>
              </div>
            )}
            <div
              className="top-sub-header"
              dangerouslySetInnerHTML={{ __html: subheaderText }}
            />
          </div>
        </div>
        {!!renderTechnologies.length && (
          <div className="gt-technologies-section wrapper">
            <div className="gt-technologies-wrapper">
              {renderTechnologies.map((technology, index) => {
                const relation = !!(technology.referenced &&
                  technology.referenced.redirectLink)
                return (
                  <div
                    className="gt-technology"
                    key={index}
                  >
                    <div className="gt-technology-top-wrapper">
                      <a
                        className="gt-technology-icon"
                        {
                        ...(relation && { href: `/technologies/${
                          slugify(technology.referenced.redirectLink)
                        }/` })
                        }
                      >
                        <Icon type={technology.imageName} />
                      </a>
                      <a
                        className="gt-technology-title"
                        {
                        ...(relation && { href: `/technologies/${
                          slugify(technology.referenced.redirectLink)
                        }/` })
                        }
                      >
                        {technology.title}
                      </a>
                    </div>
                    <div
                      className="gp-technology-description"
                      dangerouslySetInnerHTML={{ __html: technology.description }}
                    />
                    {relation && technology.referenced.buttonText && (
                      <Link
                        to={`/technologies/${
                          slugify(technology.referenced.redirectLink)
                        }/`}
                        className="technology-link"
                      >
                        {technology.referenced.buttonText}
                      </Link>
                    )}
                  </div>
                )
              })}
            </div>
            {(renderTechnologies.length < technologies.length) && (
              <div
                className="load-more"
                onClick={() => setCounter(counter + 1)}
              >
              Load more
              </div>
            )}
          </div>
        )}
        <div className="contactus-wrapper">
          <ContactUs
            contactUsDescription={howWeCanHelpYouDescription}
            contactUsSlogan={slogan}
            buttonName={buttonText}
          />
        </div>
        <RelatedItems
          relatedItems={intrestingForYouContent}
          label={label}
        />
      </section>
    </Fragment>
  )
}

export const projectsQuery = graphql`
  query ($relatedItemsTitle: [String]!) {
    header: markdownRemark (
      fileAbsolutePath: {regex: "/content/general-pages/technologies/header.md/"}
    ) {
      html
      frontmatter {
        overHeader
        header
      }    
    }
    
    seo: markdownRemark(
      fileAbsolutePath: {regex: "/content/general-pages/technologies/seo-technologies.md/"}
    ) {
      frontmatter {
        seo {
          canonical
          title
          description
          keywords
          robots
          image {
            childImageSharp {
              original {
                src
              }
            }
          }
          imageAlt
          twitterCard
        }
      }
    }
    howWeCanHelpYou: markdownRemark (
      fileAbsolutePath: {regex: "/content/general-pages/technologies/how-we-can-help-you.md/"}
    ) {
      frontmatter {
        slogan
        buttonText
      }
      html
    }
    technologies: markdownRemark (
      fileAbsolutePath: {regex: "/content/general-pages/technologies/technologies.md/"}
    ) {
      frontmatter {
        technologies {
          title
          description
          imageName 
          referenced {
            redirectLink
            buttonText
          }                   
        }
      }
    }
    interestingForYouItems: markdownRemark (
      fileAbsolutePath: {regex: "/content/general-pages/technologies/interesting-for-you.md/"}
    ) {
      frontmatter {
        label
        interestingForYou {
          name
          image {
            childImageSharp {
              fixed (width: 394, height: 237, quality: 100){
                src
              }
            }
          }
          description
          type
        }
      }
    }
    relatedItems: allMarkdownRemark(
      limit: 3
      filter: {
        frontmatter: { permalink: { in: $relatedItemsTitle }}
        fileAbsolutePath: { regex: "/content/individual-pages/"}
      } 
    ) {
      edges {
        node {
          excerpt(pruneLength: 300)
          frontmatter {
            permalink
            title
            author
            authorImage {
              childImageSharp {
                fixed (base64Width: 100){
                  base64
                }
              }
            }
            whatWeDo
            date(formatString: "DD MMM YYYY")
            image {
              childImageSharp {
                fixed (width: 394, height: 237, quality: 100){
                  src
                }
              }
            }
            description
          }
          fields {
            slug
            type
          }
        }
      }
    }
  }
`

export default TechnologiesPage
